import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import lang from './lang'
import './styles.css'

import { getStoredJson, saveJsonData, useInitialCheck } from '../../utils'
import { getDataBaseCondition, getDatabaseRows } from '../../utils/database'
import CustomModal from '../Modal'
import Tags from '../CustomHook'
import { useGlobalContext } from '../../contexts/GlobalContext'

export default function FilterPopUp({modal, setModalOpen, setUpdate}: any){
  const {globalOptions, setGlobalOptions} = useGlobalContext()
  const currLang = globalOptions.language
  const navigate = useNavigate()

  const [companies, setCompanies] = useState<any>([])
  const [plants, setPlants]       = useState<any>([])
  const [processes, setProcesses] = useState<any>([])
  const [equipments, setEquipments] = useState<any>([])
  const [inspectors, setInspectors] = useState([])
  const [inspections, setInspections] = useState<any>([])
  const [selectedReport, setSelectedReport] = useState<any>({})
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)
  const user = getStoredJson('user')

  useEffect(() => {
    if (user.company === '123456789/0001')
      setIsSuperAdmin(true)
  },[])

  useInitialCheck(importCompanies)
  useInitialCheck(importPlants, [selectedReport.company])
  useInitialCheck(importProcesses, [selectedReport.plant])
  useInitialCheck(importEquipment, [selectedReport.process])
  useInitialCheck(importInspectors)
  useInitialCheck(importInspections, [selectedReport.equipment])
  
  async function importCompanies(){
    const response = await getDatabaseRows('companies')
    setCompanies(response)
  }
  
  async function importPlants() {
    const companies = selectedReport.company;

    let allPlants: any[] = [];
    if (isSuperAdmin){
      if (!companies || companies.length === 0) {
      console.log("Nenhuma empresa para processar.");
      return;
      }
      for (const company of companies) {
          console.log(company.cnpj);
          const response = await getDataBaseCondition('plants', 'company', company.cnpj);
          allPlants = allPlants.concat(response);
      }
    } else 
      allPlants = await getDataBaseCondition('plants', 'company', user.company);

    setPlants(allPlants);
  }
  
  
  async function importProcesses(){
    const plants    = selectedReport.plant
    let allProcesses: any[] = [];
    
    if (!plants || plants.length === 0) {
      console.log("Nenhuma empresa para processar.");
      return;
    }
    for (const plant of plants) {
      
      const response = await getDataBaseCondition('processes', 'plant', plant ? plant.plant_id : '???')
      allProcesses = allProcesses.concat(response);
    }
  
    setProcesses(allProcesses)
  }

  async function importEquipment(){
    const processes    = selectedReport.process
    let allEquipments: any[] = [];

    if (!processes || processes.length === 0) {
      console.log("Nenhuma empresa para processar.");
      return;
    }
    for (const process of processes) {
      const response = await getDataBaseCondition('equipments', 'process', process.process_id)
      allEquipments = allEquipments.concat(response);
    }
    
    setEquipments(allEquipments)
  }

  async function importInspectors(){
      const response = await getDatabaseRows('users')
      setInspectors(response.filter((it: any) => it.category === 'inspector'))
  }

  async function importInspections(){
    const equipments = selectedReport.equipment
    let allInspections: any[] = [];

    if (!equipments || equipments?.length === 0) {
      console.log("Nenhuma empresa para processar.");
      return;
    }
    for (const equipment of equipments) {
      const response = await getDataBaseCondition('inspections', 'equipment', equipment.equipment_id)
      allInspections = allInspections.concat(response);
    }
    
    setInspections(allInspections)

  }
  
  function handleInspectorChange(selectedOption: any){
      if(selectedOption.key && selectedOption.key == 'default')
        return navigate('/Inspector?insert')
      
      setSelectedReport({...selectedReport, inspector: selectedOption})
  
      saveJsonData('temp', {
        inspector: selectedOption,
        ...getStoredJson('temp', {})
      })
    }

  function handleInspectionChange(selectedOption: any){
    setSelectedReport({...selectedReport, inspection: selectedOption})
  }
  
  function handleCompanyChange(selectedOption: any){
    setSelectedReport((prevState: any) => ({ ...prevState, company: selectedOption, plant: null, process: null }));
  }
  
  function handlePlantChange(selectedOption: any){
    setSelectedReport({...selectedReport, plant: selectedOption, processes: null})
  }

  function handleProcessChange(selectedOption: any){
    setSelectedReport({...selectedReport, process: selectedOption})
  }

  function handleEquipmentChange(selectedOption: any){
    setSelectedReport({...selectedReport, equipment: selectedOption})
  }

  const handleCloseModal = () => {
    setUpdate(true)
    const selectedData = {
      company: [],
      plant: [],
      process: [],
      equipment: [],
      inspector: [],
      inspection: []
    };

    if (selectedReport) {
        if (selectedReport.company && Array.isArray(selectedReport.company)) {
            selectedData.company = selectedReport.company.map((company: { cnpj: any; name: any }) => ({
                cnpj: company.cnpj,
            }));
        }
        if (selectedReport.plant && Array.isArray(selectedReport.plant)) {
            selectedData.plant = selectedReport.plant.map((plant: { plant_id: any; name: any }) => ({
                plant_id: plant.plant_id,
            }));
        }
        if (selectedReport.process && Array.isArray(selectedReport.process)) {
            selectedData.process = selectedReport.process.map((process: { process_id: any; name: any }) => ({
                process_id: process.process_id,
            }));
        }
        if (selectedReport.equipment && Array.isArray(selectedReport.equipment)) {
            selectedData.equipment = selectedReport.equipment.map((equipment: { equipment_id: any; name: any }) => ({
                equipment_id: equipment.equipment_id,
            }));
        }
        if (selectedReport.inspector && Array.isArray(selectedReport.inspector)) {
            selectedData.inspector = selectedReport.inspector.map((inspector: { inspector_id: any; name: any }) => ({
                name: inspector.name,
            }));
        }
        if (selectedReport.inspection && Array.isArray(selectedReport.inspection)) {
          selectedData.inspection = selectedReport.inspection.map((inspection: { inspection_id: any; name: any }) => ({
              inspection_id: inspection.inspection_id,
          }));
      }
    }

    saveJsonData('filterItems', selectedData);
    setModalOpen(false);
  };   
  return (
    <CustomModal modalOpen={modal} className='MainFilterContainer'>
        <div className='CentralBlock'>
          <div className='Filter'>
            <p className='FilterText'>{lang.filters[currLang]}</p>

            <div style={{padding: '.6rem'}}/>

            {/* Empresa */}
            {
              isSuperAdmin && (
                <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%' }}>
                  <Tags
                      options={companies}
                      value={selectedReport.company}
                      setValue={handleCompanyChange}
                      placeholder={lang.company[currLang]}
                      labelKey='name' 
                  /> 
                </div>
              )
            }

            <div style={{ padding: '.6rem' }}/>

            {/* Planta */}
            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%' }}>
                <Tags
                    options={plants}
                    value={selectedReport.plant}
                    setValue={handlePlantChange}
                    placeholder={lang.plant[currLang]}
                    labelKey='name'  
                /> 
            </div>

            <div style={{ padding: '.6rem' }}/>

            {/* Processo */}
            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%' }}>
                <Tags
                    options={processes}
                    value={selectedReport.process}
                    setValue={handleProcessChange}
                    placeholder={lang.process[currLang]}
                    labelKey='name'  
                /> 
            </div>

            <div style={{padding: '.6rem'}}/>

            <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%'}}>

              <Tags
                  options={equipments}
                  value={selectedReport.equipment}
                  setValue={handleEquipmentChange}
                  placeholder={lang.equipment[currLang]}
                  key='name'
              /> 
            </div>

            <div style={{padding: '.6rem'}}/>

            <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%'}}>

              <Tags
                  options={inspectors}
                  value={selectedReport.process}
                  setValue={handleInspectorChange}
                  placeholder={lang.inspector[currLang]}
                  key='name'
              /> 
            </div>

            <div style={{padding: '1rem'}}/>

            <div className='FilterButton' style={{cursor: 'pointer'}} onClick={handleCloseModal}>
              <p>{lang.showResults[currLang]}</p>
            </div>
          </div>
        </div>

        <div style={{padding: '1vw'}}/>
    </CustomModal>
  )
}