import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './styles.css';
import Sidebar from '../../components/Sidebar';
import FlagSelector from '../../components/FlagSelector';
import { DateSelector, formatTimestamp } from '../../components/DatePicker';
import { deleteJsonData, getStoredJson, saveJsonData, sendNotification, sleep, useInitialCheck } from '../../utils/index';
import { addDataBaseRow, editDatabaseByCondition, genUUID, getDataBaseCondition, getDatabaseRows } from '../../utils/database';
import CustomDropBox from '../../components/CustomDropBox';
import lang from './lang';
import { useGlobalContext } from '../../contexts/GlobalContext';
import AsyncButton from '../../components/AsyncButton';
import { NotificationTypes, getNormalizedInspectorLang } from '../../utils/consts';


export function RequestPage() {
	const { globalOptions, setGlobalOptions } = useGlobalContext()
	const currLang = globalOptions.language
	const navigate = useNavigate()

	const [companies, setCompanies] = useState([])
	const [plants, setPlants] = useState([])
	const [process, setProcess] = useState([])
	const [equipments, setEquipments] = useState([])
	const [inspectors, setInspectors] = useState([])
	const [loading, setLoading] = useState<boolean>(false)

	const [selectedCompany, setSelectedCompany] = useState<any>({})
	const [selectedInspector, setSelectedInspector] = useState<any>({})
	const [deadline, setDeadline] = useState<string>('now')
	const [startDate, setStartDate] = useState<string>('now')
	const [dateWarning, setDateWarning] = useState<boolean>(false)
	const [typeWarning, setTypeWarning] = useState<boolean>(false)

	const [selectedPlant, setSelectedPlant] = useState<any>({})
	const [selectedEquipment, setSelectedEquipment] = useState<any>({})
	const [selectedProcess, setSelectedProcess] = useState<any>({})
	const [selectedType, setSelectedType] = useState<any>({})

	const [nr13Types, setNR13Types] = useState<string[]>([])
	const [corrosionTypes, setCorrosionTypes] = useState<string[]>([])
	const [primaryTypes, setPrimaryTypes] = useState<string[]>([])

	const [edit, setEdit] = useState(false)
	const [update, setUpdate] = useState(false)

	const types = [
		{
			type: 'c',
			name: lang.corrosion[currLang]
		},
		{
			type: 'p',
			name: lang.painting[currLang]
		},
		{
			type: 'nr13',
			name: lang.NR13[currLang]
		}
	];

	useInitialCheck(initialCheck)
	useInitialCheck(importEquipmentTypes)
	useInitialCheck(importInspectors)
	useInitialCheck(importCompanies)
	useInitialCheck(importPlants, [selectedCompany, update])
	useInitialCheck(importProcess, [selectedPlant, update])
	useInitialCheck(importEquipments, [selectedProcess, update])
	
	const user = getStoredJson('user')

	async function importEquipmentTypes() {
		let types = await getDatabaseRows('equipment_type')
		types = types.filter((it: any) => it.is_secondary === '0')
		let primary = types.map((it: any) => it.id)
		setPrimaryTypes(primary)

		let nr13 = types.filter((it: any) => it.inspection_type.includes('nr13'))
		nr13 = nr13.map((it: any) => it.id)
		setNR13Types(nr13)

		let corrosion = types.filter((it: any) => it.inspection_type.includes('c'))
		corrosion = corrosion.map((it: any) => it.id)
		setCorrosionTypes(corrosion)
	}

	function handleBackupCheck() {
		const data = getStoredJson('temp', null)

		if (!data)
			return

		if (data.inspector)
			setSelectedInspector(data.inspector)

		if (data.company)
			setSelectedCompany(data.company)

		if (data.plant)
			setSelectedPlant(data.plant)

		if (data.process)
			setSelectedProcess(data.process)

		if (data.equipment)
			setSelectedEquipment(data.equipment)

		if (data.deadline)
			setDeadline(data.deadline)

		setUpdate(true)
	}

	async function initialCheck() {
		const currentUrl = window.location.href
		const isEdit = currentUrl.indexOf('?edit') != -1
		const isInsert = currentUrl.indexOf('?insert') != -1

		setEdit(isEdit)
		let data, response

		if (!isEdit && !isInsert)
			return handleBackupCheck()

		data = getStoredJson(isEdit ? 'edit' : 'insert', {})

		if (data.inspector)
			setSelectedInspector(data.inspector)

		if (data.deadline)
			setDeadline(data.deadline)

		if (data.userName) {
			response = await getDataBaseCondition('users', 'name', data.userName)
			setSelectedInspector(response[0])
		}

		if (data.company) {
			response = await getDataBaseCondition('companies', 'cnpj', data.company)
			setSelectedCompany(response[0])
		}

		if (data.plant) {
			response = await getDataBaseCondition('plants', 'plant_id', data.plant)
			setSelectedPlant(response[0])
		}

		if (data.process) {
			response = await getDataBaseCondition('processes', 'process_id', data.process)
			setSelectedProcess(response[0])
		}

		if (data.equipment) {
			response = await getDataBaseCondition('equipments', 'equipment_id', data.equipment)
			setSelectedEquipment(response[0])
		}

		setUpdate(true)
	}

	async function importCompanies() {
		let data = []

		if (user.company == '123456789/0001')
			data = await getDatabaseRows('companies')
		else
			data = await getDataBaseCondition('companies', 'cnpj', user.company)

		setCompanies(data)
	}

	useEffect(() => {
		importInspectors()
	}, [selectedType])

	async function importInspectors() {
		const response = await getDataBaseCondition('users', 'category', 'inspector')
		if (Object.keys(selectedType).length > 0){
			if (selectedType.type == 'c')
				setInspectors(response.filter((it: any) => it.type && it.type.includes('c')))
			if (selectedType.type == 'p')
				setInspectors(response.filter((it: any) => it.type && it.type.includes('p')))
			if (selectedType.type == 'nr13')
				setInspectors(response.filter((it: any) => it.type && it.type.includes('nr13')))
		} else
			setInspectors([])
	}

	async function importPlants() {
		const response = await getDataBaseCondition('plants', 'company', selectedCompany?.cnpj)
		setPlants(response)
	}

	async function importProcess() {
		const response = await getDataBaseCondition('processes', 'plant', selectedPlant?.plant_id)
		setProcess(response)
	}

	async function importEquipments() {
		let response = await getDataBaseCondition('equipments', 'process', selectedProcess?.process_id)
		response = response.filter((item: any) => primaryTypes.includes(item.equipment_type))
		setEquipments(response)
	}

	function handleCompanyChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default')
			return navigate('/Company?insert')

		setSelectedCompany(selectedOption)
		setSelectedPlant({})
		setSelectedProcess({})
		setSelectedEquipment({})

		saveJsonData('temp', {
			inspector: selectedInspector,
			deadline: deadline,
			company: selectedOption,
		})
	}

	function handlePlantChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default') {
			saveJsonData('insert', { company: selectedCompany.cnpj })
			return navigate('/Plant?insert')
		}

		setSelectedPlant(selectedOption)
		setSelectedProcess({})
		setSelectedEquipment({})

		saveJsonData('temp', {
			inspector: selectedInspector,
			deadline: deadline,
			company: selectedCompany,
			plant: selectedOption
		})
	}

	function handleProcessChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default') {
			saveJsonData('insert', { company: selectedCompany.cnpj, plant: selectedPlant.plant_id })
			return navigate('/Process?insert')
		}

		setSelectedProcess(selectedOption)
		setSelectedEquipment({})

		saveJsonData('temp', {
			inspector: selectedInspector,
			deadline: deadline,
			company: selectedCompany,
			plant: selectedPlant,
			process: selectedOption
		})
	}

	function handleEquipmentChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default') {
			saveJsonData('insert', { company: selectedCompany.cnpj, plant: selectedPlant.plant_id, process: selectedProcess.process_id })
			return navigate('/Equipment?insert')
		}

		setSelectedEquipment(selectedOption)
		setTypeWarning(false)

		saveJsonData('temp', {
			inspector: selectedInspector,
			deadline: deadline,
			company: selectedCompany,
			plant: selectedPlant,
			process: selectedProcess,
			equipment: selectedOption
		})
	}

	function handleTypeChange(selectedOption: any) {
		setSelectedType(selectedOption)
		setTypeWarning(false)

		saveJsonData('temp', {
			type: selectedOption,
			...getStoredJson('temp', {})
		})
	}

	function handleInspectorChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default')
			return navigate('/Inspector?insert')

		setSelectedInspector(selectedOption)

		saveJsonData('temp', {
			inspector: selectedOption,
			...getStoredJson('temp', {})
		})
	}

	function handleDeadlineChange(selectedDate: string) {
		setDeadline(selectedDate)
		setDateWarning(false)

		saveJsonData('temp', {
			deadline: selectedDate,
			...getStoredJson('temp', {})
		})
	}

	function handleStartDateChange(selectedDate: string) {
		setStartDate(selectedDate)

		saveJsonData('temp', {
			start_date: selectedDate,
			...getStoredJson('temp', {})
		})
	}

	async function createRequestData() {
		const company: any  = companies.filter((item: any) => item?.name == selectedCompany?.name)[0]
		const dbUser: any   = inspectors.filter((item: any) => item?.name == selectedInspector?.name)[0]

		let prefix;
		if (selectedType.type == 'p')
			prefix = 'RIP'
		else if (selectedType.type == 'c')
			prefix = 'RIC' 
		else if (selectedType.type == 'nr13')
			prefix = 'RIPS'
		else
			prefix = 'NA'

		const title: string = `${prefix} - ${selectedInspector?.name} - ${selectedCompany?.name} - ${selectedEquipment?.name}`

		return {
			id: genUUID(),
			company_id: company.id,
			user_id: dbUser.id,
			was_deleted: 0,
			is_checked: 0,
			is_draft: 0,
			company: selectedCompany?.cnpj,
			inspector: selectedInspector?.name,
			plant: selectedPlant?.plant_id,
			equipment: selectedEquipment.equipment_id,
			process: selectedProcess?.process_id,
			start_date: startDate,
			deadline: deadline,
			title: title,
			last_modified_at: '',
			server_created_at: '',
			requested_by: getStoredJson('user').id,
			type: selectedType.type,
		}
	}
	// corrosao: 3, 7
	// nr13: 12, 11, 8
	async function handleSave() {
		if (deadline < startDate)
			return setDateWarning(true)

		if (selectedType.type === 'nr13' && !nr13Types.includes(selectedEquipment.equipment_type))
			//return alert(lang.safetyInspectionAvailability[currLang])
			return setTypeWarning(true)

		if (selectedType.type !== 'nr13' && !corrosionTypes.includes(selectedEquipment.equipment_type))
			return setTypeWarning(true)
		
		let requestData = await createRequestData()
		const response = await addDataBaseRow('inspections', requestData)

		if (!response || response.status != 'success')
			return alert('erro ao realizar inspeção')

		const inspectorLang = selectedInspector.lang ? selectedInspector.lang : 'en_US'
		const deadlineDate = requestData.deadline.split(' ')[0]
		const dateFormatLang = inspectorLang ? inspectorLang.replace('_', '-') : null
		const formattedDeadline = new Date(deadlineDate).toLocaleDateString(dateFormatLang)
		const translation = getNormalizedInspectorLang(inspectorLang)

		await sendNotification({
			itemId: requestData.id,
			userId: requestData.user_id,
			type: NotificationTypes.NOTIFY_NEW_INSPECTION,
			title: lang.notification.title[translation],
			msg: getStoredJson('user')?.name + lang.notification.content[translation] + requestData.title,
			data: {
				requestedBy: getStoredJson('user')?.name,
				inspectionTitle: requestData.title,
				deadline: formattedDeadline
			}
		})
		
		
		deleteJsonData('temp')
		navigate('/Home')
	}

	async function handleEdit() {
		if (deadline < startDate)
			return setDateWarning(true)

		let requestData: any = await createRequestData()
		let stored = getStoredJson('edit')

		if (stored.inspector != requestData.inspector) {
			const insertResponse = await addDataBaseRow('inspections', { ...requestData, id: genUUID() })

			if (insertResponse.status != 'success')
				return alert('erro ao atualizar inspeção')

			requestData.was_deleted = 1;
			requestData.user_id = stored.user_id
			requestData.inspector = stored.inspector
			requestData.title = stored.title
		}

		delete requestData.id
		delete requestData.server_created_at

		const response = await editDatabaseByCondition('inspections', 'id', stored.id, requestData)

		if (!response || response.status != 'success')
			return alert('erro ao atualizar inspeção')

		deleteJsonData('temp')
		navigate('/Home')
	}

	function cleanData() {
		deleteJsonData('temp')

		setSelectedType({})
		setSelectedCompany({})
		setSelectedPlant({})
		setSelectedProcess({})
		setSelectedInspector({})
		setSelectedEquipment({})
		setDeadline('now')
		setStartDate('now')
	}

	function isFieldsEmpty() {
		const data = [
			selectedType?.name,
			selectedCompany?.name,
			selectedInspector?.name,
			selectedEquipment?.name,
			selectedProcess?.name,
			selectedPlant?.name,
		]

		for (let value of data) {
			if (!value)
				return true

			if (value.trim().length == 0)
				return true
		}

		return false
	}

	function isAnyFieldFilled() {
		const data = [
			selectedCompany?.name,
			selectedInspector?.name,
			selectedEquipment?.name,
			selectedProcess?.name,
			selectedPlant?.name,
		]

		for (let value of data) {
			if (value?.trim()?.length > 0)
				return true
		}

		return false
	}


	return (
		<div className="MainContainer">
			<div className='SideBarCompensation' />

			<div className='CentralBlock' style={{ alignItems: 'center', justifyContent: 'center', margin: 0, padding: 0 }}>
				<FlagSelector />
				<Sidebar activePage='Register' />
				
				<div className="RegisterMainContainer">
					<div style={{ display: 'flex', justifyContent: 'space-between', width: '85%', alignItems: 'center' }}>
						<p className='text-client'>{edit ? lang.editInspection[currLang] : lang.newInspection[currLang]}</p>

						<div
							className='cleanButtonRequest'
							style={isAnyFieldFilled() ? {} : { opacity: .5, cursor: 'not-allowed' }}
							onClick={isAnyFieldFilled() ? () => cleanData() : () => null}
						>
							{lang.clean[currLang]}
						</div>
					</div>

					<div style={{ padding: '.9rem' }} />

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<CustomDropBox
							options={types}
							value={selectedType?.name ? selectedType?.name : ''}
							setValue={handleTypeChange}
							placeholder={lang.type[currLang]}
							key='name'
						/>
					</div>

					<div style={{ padding: '.7rem' }} />

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<CustomDropBox
							options={inspectors}
							value={selectedInspector?.name ? selectedInspector?.name : ''}
							setValue={handleInspectorChange}
							placeholder={lang.inspector[currLang]}
							key='name'
							callbackKey={lang.addNew[currLang]}
						/>
					</div>

					<div style={{ padding: '.7rem' }} />

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<CustomDropBox
							options={companies}
							value={selectedCompany?.name ? selectedCompany?.name : ''}
							setValue={handleCompanyChange}
							placeholder={lang.company[currLang]}
							key='name'
							callbackKey={lang.addNew[currLang]}
						/>
					</div>

					<div style={{ padding: '.7rem' }} />

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<CustomDropBox
							options={plants}
							value={selectedPlant?.name ? selectedPlant?.name : ''}
							setValue={handlePlantChange}
							placeholder={lang.plant[currLang]}
							key='name'
							callbackKey={lang.addNew[currLang]}
						/>
					</div>

					<div style={{ padding: '.7rem' }} />

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<CustomDropBox
							options={process}
							value={selectedProcess?.name ? selectedProcess?.name : ''}
							setValue={handleProcessChange}
							placeholder={lang.process[currLang]}
							key='name'
							callbackKey={lang.addNew[currLang]}
						/>
					</div>

					<div style={{ padding: '.7rem' }} />

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<CustomDropBox
							options={equipments}
							value={selectedEquipment?.name ? selectedEquipment?.name : ''}
							setValue={handleEquipmentChange}
							placeholder={lang.equipment[currLang]}
							key='name'
							callbackKey={lang.addNew[currLang]}
						/>
					</div>

					<div style={{display: 'flex', justifyContent: 'space-between', width: '85%'}}>
						<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '49%' }}>
							<DateSelector
								onChange={handleStartDateChange}
								value={startDate ? startDate : null}
								placeholder={lang.startDate[currLang]}
							/>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '49%' }}>
							<DateSelector
								onChange={handleDeadlineChange}
								value={deadline ? deadline : null}
								placeholder={lang.deadline[currLang]}
							/>
						</div>

						
					</div>

					<div>
						{dateWarning ? <p style={{ color: 'red'}}>{lang.dateWarning[currLang]}</p> : null}
					</div>

					<div>
						{typeWarning ? <p style={{ color: 'red'}}>{lang.safetyInspectionAvailability[currLang]}</p> : null}
					</div>

					<div style={{ display: 'flex', width: '100%', height: '3.5rem', justifyContent: 'center' }}>
						<AsyncButton
							active={loading || isFieldsEmpty()}
							text={edit ? lang.edit[currLang] : lang.register[currLang]}
							className='buttonrequest'
							onClick={
								edit
									?
									async () => { setLoading(true); await handleEdit(); setLoading(false) }
									:
									async () => { setLoading(true); await handleSave(); setLoading(false) }
							}
							loading={loading}
						/>
					</div>

					

				</div>
			</div>
		</div>
	);
}